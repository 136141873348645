<template>
  <div :class="$t(`btn-toggle ${this.isActive ? 'active' : ''}`)">
    <div class="toggle__text">Country Mode</div>
    <div class="toggle__circle" @click="toggleActive"></div>
    <div class="toggle__text toggle__text__last">City Mode</div>
  </div>
</template>

<script>
  export default {
    name: "Toggle",
    props: {
      callbackHandler: {
        type: Function,
        default: () => {},
      },
    },
    data() {
      return {
        isActive: false,
      };
    },
    methods: {
      toggleActive() {
        this.isActive = !this.isActive;
        this.$emit("update", this.isActive);
        // this.$parent.toggleCallback(this.isActive);
      },
    },
  };
</script>

<style lang="scss">

  .btn-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 12;

    .toggle__text {
      font-size: 14px;
      line-height: 18px;
      color: #ffffff;
      transition: 0.26s;

      &__last {
        opacity: 1;
      }
    }

    .toggle__circle {
      width: 34px;
      height: 20px;
      border-radius: 24px;
      background-color: #c4d0da;
      position: relative;
      margin: 0 16px;
      cursor: pointer;

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 2px;
        transform: translateY(-50%) translateX(0);
        height: 16px;
        width: 16px;
        border-radius: 50%;
        background-color: #ffffff;
        transition: 0.36s;
      }
    }

    &.active {
      .toggle__text {
        opacity: 1;

        &__last {
          opacity: 1;
        }
      }

      .toggle__circle {
        &::after {
          transform: translateY(-50%) translateX(calc(100% - 2px));
          background-color: #000000;
        }
      }
    }
  }

</style>
