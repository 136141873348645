<template>
  <div class="bubble-maps-page">
    <div class="row">
      <div class="flex md12 xs12">

        <vac-card
          class="bubble-maps-page__widget vac-card--white"
          :title="title">

          <ExcelPreviewModal
            v-if="isPreview"
            :data="preparedData"
            header="Browsers"
            @disable="togglePreview"
            @exportToExcel="exportToExcel"
          />
          <Actions v-if="!forReport" :export-to-excel="exportToExcel" @togglePreview="togglePreview" is-preview/>

          <bubble-map
            ref="bubbleMap"
            style="height: 65vh;"
            :for-report="forReport"
            :fillMap="fillMap"
          />

        </vac-card>

      </div>
    </div>
  </div>
</template>

<script>
  import BubbleMap from '../maps/bubble-maps/BubbleMap';
  import HelperExcel from '../../services/Helpers/HelperExcel';
  import Actions from "../actions/Actions";
  import ExcelPreviewModal from "@/components/actions/ExcelPreviewModal";

  export default {
    name: 'CountriesMap',
    props: {
      data: {
        type: Object,
        default: () => {},
      },
      title: {
        type: String,
        default: "",
      },
      fillMap: {
        type: Boolean,
        default: false,
      },
      forReport: {
        type: Boolean,
        default: false
      }
    },
    components: {
      BubbleMap,
      Actions,
      ExcelPreviewModal
    },

    data() {
      return {
        mapData: this.data,
        isPreview: false
      };
    },

    computed: {
      preparedData () {
        const { data } = this.mapData;

        const returnObj = {}
        returnObj.labels = ["Country | City", "Count"]
        returnObj.maxLength = []

        for (let key = 0; key < returnObj.labels.length; key++) {
          returnObj.maxLength[key] = returnObj.labels[key].length;
        }

        returnObj.datasets = [];
        data.forEach(info => {
            const obj = {
              data: [info.name, info.value]
            };
            returnObj.maxLength[0] = Math.max(returnObj.maxLength[0], String(info.name).length)
            returnObj.maxLength[1] = Math.max(returnObj.maxLength[1], String(info.value).length)
            returnObj.datasets.push(obj);
          });

        return returnObj;
      }
    },

    methods: {
      exportToExcel() {
        const { data } = this.mapData;

        const labels = ["Country | City", "Count"];
        const datasets = [];

        data.forEach(info => {
          const obj = {
            "Country | City": info.name,
            "Count": info.value,
          };
          datasets.push(obj);
        });

        HelperExcel.exportToExcel({}, 'Countries Stats', labels, datasets);
      },

      drawMap(data = {}) {
        this.mapData = data;
        this.$refs.bubbleMap.drawMap(data);
      },

      togglePreview() {
        this.isPreview = !this.isPreview;
      }
    },
  };
</script>

<style lang="scss">
  .bubble-maps-page__widget {
    background-color: #212327;

    svg * {
      transition: 0.6s fill;
    }
  }
</style>
