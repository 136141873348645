<template>
  <div class="map-container">
    <Toggle
      v-if="!forReport"
      firstLabel="Countries Map"
      secondLabel="City Map"
      @update="toggleCallback" />

    <CountriesMap
      v-if="!isActive"
      class="map-countries"
      ref="countriesMap"
      :for-report="forReport"
      :fillMap="true"
      :title="$t('Country map')" />

    <CountriesMap
      v-if="isActive"
      class="map-ip"
      ref="ipMap"
      :for-report="forReport"
      :title="$t('City map')" />

  </div>
</template>

<script>
  import CountriesMap from '../stats/CountriesMap';
  import Toggle from "../toggle/Toggle";

  export default {
    name: "Maps",
    components: {
      Toggle,
      CountriesMap,
    },
    props: {
      countriesData: {
        type: Object,
        default: () => {},
      },
      ipData: {
        type: Object,
        default: () => {},
      },
      forReport: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        isActive: false,
      };
    },
    watch: {
      countriesData: {
        immediate: true,
        deep: true,
        handler(newValue) {
          if (!this.isActive && this.$refs.countriesMap) {
            this.$refs.countriesMap.drawMap(newValue);
          }
        },
      },
      ipData: {
        immediate: true,
        deep: true,
        handler(newValue) {
          if (this.isActive && this.$refs.ipMap) {
            this.$refs.ipMap.drawMap(newValue);
          }
        },
      },
    },
    methods: {
      toggleCallback(isActive) {
        this.isActive = isActive;
      },
    },
    mounted() {
      if (!this.isActive) {
        this.$refs.countriesMap.drawMap(this.countriesData);
      } else {
        this.$refs.ipMap.drawMap(this.ipData);
      }
    },
    updated() {
      if (!this.isActive) {
        this.$refs.countriesMap.drawMap(this.countriesData);
      } else {
        this.$refs.ipMap.drawMap(this.ipData);
      }
    },
  };
</script>

<style lang="scss">

  .map-container {
    position: relative;
  }

  .btn-toggle {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 2em;
    z-index: 20;
  }
</style>
